<template>
  <v-dialog v-model="dialog" class="mx-auto" scrollable :overlay="false" max-width="1000px">
    <v-card class="my-auto mx-auto" :loading="loading">
      <v-card-title primary-title>
        <v-icon color="red darken-2">mdi-traffic-cone</v-icon>
        {{ title }}
      </v-card-title>
      <v-container>
        <v-row>
          <v-col sm="6" md="6">
            <v-list v-if="details.length > 0" two-line>
              <v-list-item v-for="(item, i) in details" :key="i">
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-information-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
                  <v-list-item-title>{{ item.data }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col v-if="details.length > 0" sm="6" md="6" align-self="center" class="text-center">
            <longdo-map @load="loadMap" :zoom="14" :lastView="false" v-if="visible">
            </longdo-map>
            <!-- <iframe :src="gmapEmbed" frameborder="0" style="border:0" width="300" height="300"></iframe> -->
          </v-col>
        </v-row>
        <!-- Cameras -->
        <v-row v-if="cameras.length > 0">
          <v-col>
            <v-icon>
              mdi-cctv
            </v-icon>
            CCTV
          </v-col>
          <v-row justify="start" align-content="start">
            <v-col v-for="(c, i) in cameras" :key="i">
              <v-btn :href="c.uri" target="_blank" color="primary">
                <v-icon small>mdi-camera</v-icon>
                {{ c.name }}
              </v-btn>
            </v-col>
          </v-row>
        </v-row>

        <!-- end of cameras -->
        <v-row v-if="officers.length > 0">
          <v-col>
            <v-simple-table height="300px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      ชื่อ-นามสกุล
                    </th>
                    <th class="text-left">
                      ตำแหน่ง
                    </th>
                    <th class="text-left">
                      หน้าที่
                    </th>
                    <th class="text-left">
                      เบอร์โทร
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in officers" :key="item.officer.full_name">
                    <td>{{ item.officer.full_name }}</td>
                    <td>{{ item.officer.position }}</td>
                    <td>{{ item.type.name }}</td>
                    <td>{{ item.officer.phone }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn text @click="dialog = false">ปิด</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="white--text" @click="editCheckpoint" v-show="checkpoint.can.update">
          <v-icon>
            mdi-pencil
          </v-icon>
          แก้ไข
        </v-btn>
      </v-card-actions>
    </v-card>
    <cp-edit-dlg ref="cpDialog" v-model="editDialog" :allowDates="allowDates" type="edit"
      @cancelClick="editDialog = false">
    </cp-edit-dlg>
  </v-dialog>
</template>

<script>
import { show } from '@/api/checkpoint'
import StaticMap from 'vue-static-map'
// edit dialog
import dialogForm from '@/views/command/checkpoint/dialogForm'

import { LongdoMap } from 'longdo-map-vue'
// LongdoMap.init({ apiKey:  process.env.VUE_APP_LONGDOMAP_API_KEY })

export default {
  props: {
    value: {},
    visible: false
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    gmapApi() {
      return process.env.VUE_APP_LONGDO_API_KEY
    },
    gmapEmbed() {
      return `https://www.google.com/maps/embed/v1/place?key=${this.gmapApi}&q=${this.checkpoint.lat},${this.checkpoint.lng}`
    },
  },
  methods: {
    loadMap(map) {

      map.Overlays.clear();

      map.Layers.setBase(longdo.Layers.NORMAL);
      map.Layers.add(longdo.Layers.TRAFFIC);

      if (this.checkpoint.lat && this.checkpoint.lng) {
        map.location({ lon: this.checkpoint.lng, lat: this.checkpoint.lat }, true);
        var marker = new longdo.Marker({ lon: this.checkpoint.lng, lat: this.checkpoint.lat });
        map.Overlays.add(marker);
      } else {
        map.location(longdo.LocationMode.Geolocation);
      }

      // setMapAddress()

      // this.localtionChange(map)

      // map.Event.bind('location', this.localtionChange(map));

      var _this = this

      
    },
    cancelDialog() {
      this.officers = {}
      this.checkpoint = {
        can: {
          update: true,
          open: true,
          delete: true,
        },
      }
      this.allowDates = []
      this.title = ''
      this.details = []
      this.editDialog = false
    },

    getCheckpointDetail(id) {
      this.loading = true
      show(id)
        .then(res => {
          this.title = res.name
          this.details = []
          this.checkpoint = Object.assign({}, res)
          this.officers = res.officers
          this.addDetail(res)
          this.allowDates = res.command_allowed_dates
          this.cameras = res.cameras
          // console.log(this.checkpoint)
        })
        .finally(() => {
          this.loading = false
        })
    },
    open(id) {
      this.getCheckpointDetail(id)
      this.dialog = true
    },
    // v-list item
    addDetail(data) {
      const date = this.moment(data.plan.plan_date)
        .add(543, 'year')
        .format('D MMM YYYY')
      this.details.push(
        {
          title: 'วันที่',
          data: `${this.moment(data.start_at)
            .add(543, 'year')
            .format('D MMMYY (HH:mm')} - ${this.moment(data.end_at)
              .add(543, 'year')
              .format('HH:mm น.)')}`,
        },
        {
          title: 'สถานที่ตั้ง',
          data: `${data.road}(${data.inbound == 1 ? 'ขาเข้า' : 'ขาออก'}) ${data.address}`,
        },
        {
          title: 'พิกัด',
          data: `${data.lat}, ${data.lng}`,
        },
        {
          title: 'คำสั่งที่',
          data: `คำสั่ง ${data.station.name_abbr} ที่ ${data.plan.plan_number} ลง ${date}`,
        },
        {
          title: 'หมายเหตุ',
          data: data.remark || '-',
        }
      )
      //   console.log(this.details)
    },
    editCheckpoint() {
      this.$refs.cpDialog.initCheckpoint2(this.checkpoint)
      this.editDialog = true
    },
  },
  components: {
    cpEditDlg: dialogForm,
    LongdoMap
  },
  data() {
    return {
      details: [],
      loading: false,
      editDialog: false,
      allowDates: [],
      title: '',
      checkpoint: {
        can: {
          update: true,
          open: true,
          delete: true,
        },
      },
      officers: [],
      cameras: [],
    }
  },
}
</script>

<style></style>

<template>
  <v-card :color="outlined ? '' : color" class="ml-auto" :dark="!outlined" @click="onClick" :outlined="outlined">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="h6" style="text-overflow: ellipsis;" v-text="title"></v-card-title>

        <v-card-subtitle v-text="number" class="display-3 "></v-card-subtitle>

        <!-- <v-card-actions>
          <v-btn absolute class="ml-2 mt-3" fab icon height="40px" right width="40px">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </v-card-actions> -->
      </div>
      <v-avatar class="ma-3" size="125" tile>
        <v-icon size="125" :color="textcolor">{{ icon }}</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {},
    number: {},
    icon: {},
    outlined: {
      type: Boolean,
      default: false,
    },
    textcolor: {
      type: String,
      default: 'white',
    },
    color: {},
    onClick: {
      type: Function,
      default() {
        return () => {}
      },
    },
  },
}
</script>

<style></style>

<template>
  <v-speed-dial absolute top left direction="right" open-on-hover transition="fade-transition">
    <template v-slot:activator>
      <v-btn class="mx-2" fab dark color="blue">
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab dark small color="green" @click="$emit('showAll')" v-on="on" v-bind="attrs">
          <v-icon>mdi-map-marker-multiple-outline</v-icon>
        </v-btn>
      </template>
      <span>แสดงจุดตรวจทั้งหมด</span>
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab dark small color="red" @click="$emit('toggleTrafficLayer')" v-on="on" v-bind="attrs">
          <v-icon>mdi-traffic-light</v-icon>
        </v-btn>
      </template>
      <span>เปิด/ปิดสภาพการจราจร</span>
    </v-tooltip>

    <!-- <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab dark small color="indigo" @click="$emit('toggleFullscreen')" v-on="on" v-bind="attrs">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
      </template>
      <span>แสดงเต็มจอ</span>
    </v-tooltip> -->
  </v-speed-dial>
</template>

<script>
export default {}
</script>

<style></style>

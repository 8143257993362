<template>
  <v-row no-gutters class="mt-2 mb-2">
    <v-col cols="12" sm="6" lg="3" xl="3" v-for="(item, i) in stats" :key="`col-${i}`">
      <info-card
        :key="i"
        :title="item.name"
        icon="mdi-poll"
        :number="item.count"
        :number2="item.arrest_count"
        color="red"
      ></info-card>
    </v-col>
  </v-row>
</template>

<script>
import infoCard2 from '@/components/infoCard2'
import { getChargeStat } from '@/api/charge'
export default {
  methods: {
    async getStat(ids) {
      this.stats = await getChargeStat({ ids: ids })
    },
  },
  data() {
    return {
      stats: [],
    }
  },
  components: {
    infoCard: infoCard2,
  },
}
</script>

<style></style>

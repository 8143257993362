import http from './http'

export function getMarkers(ids) {
  return http({
    url: `/api/v2/gmapMarkers`,
    method: 'post',
    data: {
      eventIds: ids,
    },
  })
}

export function getApprovalMarkers(ids) {
  return http({
    url: `/api/v2/gmapApprovalMarkers`,
    method: 'get',
  })
}

<template>
  <v-container fluid>
    <v-toolbar flat outlined rounded class="mb-4">
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down d-md-flex ">
        <v-switch
          v-model="showPermanent"
          :disabled="loading"
          label="แสดงด่านถาวร"
          :hide-details="true"
          inset
          class="mr-4"
        ></v-switch>
        <v-switch
          v-model="showToday"
          :disabled="loading"
          label="แสดงเฉพาะจุดตรวจวันนี้"
          :hide-details="true"
          inset
          class="mr-4"
        ></v-switch>
        <v-switch
          v-model="showActive"
          :disabled="loading"
          label="แสดงเฉพาะที่ตั้งจริง"
          :hide-details="true"
          inset
        ></v-switch>
        <v-switch
          v-model="showNotFinish"
          :disabled="loading"
          label="แสดงเฉพาะที่ยังตั้งอยู่"
          :hide-details="true"
          inset
        ></v-switch>
        <v-switch
          v-model="showMap"
          :disabled="loading"
          label="แสดงแผนที่"
          :hide-details="true"
          inset
          class="mr-4"
        ></v-switch>
      </div>
      <!-- MENU SMALL SCREEN -->

      <v-toolbar-items class="hidden-md-and-up">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text>
              <v-icon>mdi-filter</v-icon>
              ตัวกรอง
            </v-btn>
          </template>
          <v-list class="hidden-md-and-up">
            <v-list-item>
              <v-switch
                v-model="showPermanent"
                :disabled="loading"
                label="แสดงด่านถาวร"
                :hide-details="true"
                inset
                class="mr-4"
              ></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch
                v-model="showToday"
                :disabled="loading"
                label="แสดงเฉพาะจุดตรวจวันนี้"
                :hide-details="true"
                inset
                class="mr-4"
              ></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch
                v-model="showActive"
                :disabled="loading"
                label="แสดงเฉพาะที่ตั้งจริง"
                :hide-details="true"
                inset
              ></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch
                v-model="showNotFinish"
                :disabled="loading"
                label="แสดงเฉพาะที่ยังตั้งอยู่"
                :hide-details="true"
                inset
              ></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch
                v-model="showMap"
                :disabled="loading"
                label="แสดงแผนที่"
                :hide-details="true"
                inset
                class="mr-4"
              ></v-switch>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <!-- calendar & maps -->
    <v-row>
      <v-col cols="12" md="12">
        <tpcc-calendar
          @events-set="loadMarkers"
          @setMapCenter="setMapCenter"
          @event-click="markerClick"
          :show-permanent="showPermanent"
          :show-today="showToday"
          :show-active="showActive"
          :show-not-finish="showNotFinish"
          :only-type="checkpoint_type"
          :only-sub-type="checkpoint_sub_type"
          ref="tpccCalendar"
        />
      </v-col>
      <v-col cols="12" md="12" v-if="showMap">
        <tpcc-gmap ref="gmap" :map-height="mh" @markerDblClick="markerClick" />
      </v-col>
    </v-row>
    <v-row class="pb-0">
      <v-col>
        <p class="text-h5">สรุปจำนวน แยกตามประเภทหลัก</p>
        <p class="text-subtitle-1">
          * สามารถคลิกที่ Information Card เพื่อกรองการแสดงผลในปฏิทินได้
        </p>
      </v-col>
    </v-row>
    <!-- info card -->
    <v-row>
      <v-col cols="12" sm="6" lg="3" xl="3" v-for="(item, i) in infocards" :key="`col-${i}`">
        <info-card
          :key="i"
          :title="item.title"
          :icon="item.icon"
          :number="item.number"
          :color="item.color"
          :footer="item.footer"
          :on-click="
            () => {
              checkpoint_type = item.type
            }
          "
        ></info-card>
      </v-col>
    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row class="pb-0">
      <v-col>
        <p class="text-h5">แยกตามประเภท</p>
        <p class="text-subtitle-1">
          * 1 จุดตรวจมีการปฏิบัติได้หลายประเภท สรุปจำนวนด้านบนอาจจะไม่เท่ากับจุดตรวจแยกด้านล่างนี้
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" lg="3" xl="3" v-for="(item, i) in subInfoCard" :key="`col-${i}`">
        <info-card
          :key="i"
          :title="item.title"
          :icon="item.icon"
          :number="item.number"
          :color="item.color"
          :footer="item.footer"
          :outlined="item.outlined"
          :textcolor="item.textcolor"
          :on-click="
            () => {
              checkpoint_sub_type = item.type
            }
          "
        ></info-card>
      </v-col>
    </v-row>
    <!-- stat tables -->
    <v-row>
      <v-col
        cols="12"
        v-if="divisions.length == 0"
        :md="divisions.length != 0 ? 6 : 12"
        v-role="[`super-admin`, `commander`]"
      >
        <b-stat :events="events"></b-stat>
      </v-col>
      <v-col
        cols="12"
        :md="divisions.length != 0 ? 12 : 6"
        v-if="divisions.length != 0 && stations.length == 0"
        v-role="[`super-admin`, `commander`, `bureau-admin`]"
      >
        <d-stat :events="events"></d-stat>
      </v-col>
    </v-row>

    <!-- station stat table -->
    <v-row>
      <v-col cols="12">
        <s-stat
          :events="events"
          v-if="stations.length != 0"
          v-role="[`super-admin`, `commander`, `bureau-admin`, `division-admin`]"
        ></s-stat>
      </v-col>
    </v-row>
    <checkpoint-dialog ref="cdialog" v-model="cDialog" />

    <!-- arrest info -->
    <!-- <arrest-stat ref="astat"></arrest-stat> -->

    <!-- TODO ADD SUBTYPE STAT TABLE  -->
  </v-container>
</template>

<script>
// import tpccGmap from './sections/gmaps'
import tpccGmap from './sections/longdoMap'
import tpccCalendar from './sections/calendar'
import checkpointDialog from '@/components/checkpointDetailDialog'
import infoCard from '@/components/infoCard'
import bStat from './sections/bureauStat'
import dStat from './sections/divisionStat'
import sStat from './sections/stationStat'
import arrestStat from './sections/arrestStat'
import { getStat } from '@/api/drunkreport'
import { mapGetters } from 'vuex'
export default {
  components: {
    tpccGmap,
    tpccCalendar,
    checkpointDialog,
    infoCard,
    bStat,
    dStat,
    sStat,
    arrestStat,
  },

  computed: {
    mh() {
      let mh = window.innerHeight - 340
      return `${mh}px`
    },
    ...mapGetters('Appfilter', ['divisions', 'stations', 'datesSelected']),
  },
  watch: {
    datesSelected(v) {
      if (v != null) {
        this.showToday = false
      } else {
        this.showToday = true
      }
    },
    checkpoint_type(v) {
      if (v == null) {
        this.checkpoint_sub_type = null
      }
    },
    showMap(v) {
      this.$refs.tpccCalendar.updateSize()
      this.$refs.tpccCalendar.refreshFc()
    },
  },
  methods: {
    loadMarkers(events) {
      this.loading = true
      this.events = events
      let eventsIds = events.map(({ id }) => id)

      if (this.showMap) {
        this.$refs.gmap.fetchMarkers(eventsIds)
      }

      // this.$refs.astat.getStat(eventsIds)
      this.setInfoBox(events)
      this.setSubInfo(events)
      this.loading = false
    },
    async setInfoBox(events) {
      this.infocards[0].number = events.length
      this.infocards[1].number = events.filter(e => e.extendedProps.type == 1).length
      this.infocards[2].number = events.filter(e => e.extendedProps.type == 2).length
      this.infocards[3].number = events.filter(e => e.extendedProps.type == 3).length
      // this.infocards[1].number = events.filter(e => e.extendedProps.type == 1).length
      // this.infocards[2].number = events.filter(e => e.extendedProps.type == 3).length
      // this.infocards[3].number = events.filter(e => e.extendedProps.type == 6).length
      // this.infocards[4].number = events.filter(e => e.extendedProps.type == 2).length
      // this.infocards[5].number = events.filter(e => e.extendedProps.type == 5).length
      // this.infocards[6].number = events.filter(e => e.extendedProps.type == 4).length
      // const drunk = await getStat({ ids: events.map(({ id }) => id) })
      // this.infocards[7].number = drunk.count
    },
    setSubInfo(events) {
      this.subInfoCard[0].number = events.filter(e => e.extendedProps.subtype.includes(1)).length
      this.subInfoCard[1].number = events.filter(e => e.extendedProps.subtype.includes(2)).length
      this.subInfoCard[2].number = events.filter(e => e.extendedProps.subtype.includes(4)).length
      this.subInfoCard[3].number = events.filter(e => e.extendedProps.subtype.includes(5)).length
      this.subInfoCard[4].number = events.filter(e => e.extendedProps.subtype.includes(6)).length
      this.subInfoCard[5].number = events.filter(e => e.extendedProps.subtype.includes(7)).length
      this.subInfoCard[6].number = events.filter(e => e.extendedProps.subtype.includes(8)).length
      this.subInfoCard[7].number = events.filter(e => e.extendedProps.subtype.includes(9)).length
    },
    setMapCenter(center) {
      if (this.showMap) this.$refs.gmap.setCenter(center)
    },
    markerClick(id) {
      this.cDialog = true
      this.$refs.cdialog.getCheckpointDetail(id)
    },
    selectType(id) {
      this.checkpoint_type = id
    },
  },
  data() {
    return {
      mapH: '500px',
      cDialog: false,
      events: [],
      showMap: false,
      showLive: true,
      showActive: true,
      showToday: true,
      showNotFinish: false,
      showPermanent: false,
      loading: false,
      checkpoint_type: null,
      checkpoint_sub_type: null,
      infocards: [
        {
          title: 'ทั้งหมด',
          icon: 'mdi-traffic-cone',
          number: 0,
          color: 'orange',
          footer: '',
          type: null,
        },
        {
          title: 'จุดตรวจ',
          icon: 'mdi-traffic-cone',
          number: 0,
          color: 'red darken-2',
          footer: '',
          type: 1,
        },
        {
          title: 'ด่านตรวจ',
          icon: 'mdi-sign-caution',
          number: 0,
          color: 'green lighten-1',
          footer: '',
          type: 2,
        },
        {
          title: 'จุดสกัด',
          icon: 'mdi-car-emergency',
          number: 0,
          color: 'teal',
          footer: '',
          type: 3,
        },
        // {
        //   title: 'ด่านเมา',
        //   icon: 'mdi-bottle-tonic',
        //   number: 0,
        //   color: 'green',
        //   footer: '',
        //   type: 2,
        // },
        // {
        //   title: 'นน.รถบรรทุก',
        //   icon: 'mdi-weight-kilogram',
        //   number: 0,
        //   color: 'indigo',
        //   footer: '',
        //   type: 5,
        // },
        // {
        //   title: 'พรก.ฉุกเฉิน',
        //   icon: 'mdi-account-check-outline',
        //   number: 0,
        //   color: 'indigo',
        //   footer: '',
        //   type: 4,
        // },
        // {
        //   title: '> 50mg%',
        //   icon: 'mdi-bottle-tonic-skull',
        //   number: 0,
        //   color: 'red',
        //   footer: '',
        // },
      ],
      subInfoCard: [
        {
          title: 'กวดขันฯ',
          icon: 'mdi-bike-fast',
          number: 0,
          color: 'green',
          footer: '',
          type: 1,
          outlined: true,
          textcolor: 'green darken-4',
        },
        {
          title: 'ตรวจเมา',
          icon: 'mdi-bottle-wine',
          number: 0,
          color: 'white',
          footer: '',
          type: 2,
          outlined: true,
          textcolor: 'yellow darken-4',
        },
        {
          title: 'พรก.ฉุกเฉิน',
          icon: 'mdi-account-check-outline',
          number: 0,
          color: 'indigo',
          footer: '',
          type: 4,
          outlined: true,
          textcolor: 'red darken-4',
        },
        {
          title: 'นน.รถบรรทุก',
          icon: 'mdi-weight-kilogram',
          number: 0,
          color: 'indigo',
          footer: '',
          type: 5,
          outlined: true,
          textcolor: 'grey darken-4',
        },
        {
          title: 'ปป.',
          icon: 'mdi-handcuffs',
          number: 0,
          color: 'indigo',
          footer: '',
          type: 6,
          outlined: true,
          textcolor: 'bluegrey darken-4',
        },
        {
          title: 'ยาเสพติด',
          icon: 'mdi-needle',
          number: 0,
          color: 'indigo',
          footer: '',
          type: 7,
          outlined: true,
          textcolor: 'orange darken-4',
        },
        {
          title: 'COVID',
          icon: 'mdi-virus',
          number: 0,
          color: 'indigo',
          footer: '',
          type: 8,
          outlined: true,
          textcolor: 'purple darken-4',
        },
        {
          title: 'ควันดำ',
          icon: 'mdi-cloud',
          number: 0,
          color: 'indigo',
          footer: '',
          type: 9,
          outlined: true,
          textcolor: 'grey darken-4',
        },
      ],
    }
  },
}
</script>

<style></style>

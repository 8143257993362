<template>
  <v-card>
    <v-card-title>
      สรุปแยกตามสถานี
    </v-card-title>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left border-1" rowspan="2">
              สถานี
            </th>
            <th class="text-right border-1" rowspan="2">
              จุด/ด่านตรวจทั้งหมด
            </th>
            <th class="text-right border-1" rowspan="2">
              ด่านตรวจ
            </th>
            <th class="text-right border-1" rowspan="2">
              จุดสกัด
            </th>
            <th class="text-center border-1" rowspan="2">
              จุดตรวจ
            </th>
            <th class="text-center border-1" colspan="11">
              รายละเอียดจุดตรวจ (แยกตามประเภท)
            </th>
          </tr>
          <tr>
            <!-- <th class="text-right border-1">
              รวม
            </th> -->
            <th class="text-right border-1">
              จุดกวดขันวินัยจราจร
            </th>
            <th class="text-right border-1">
              จุดตรวจวัดแอลกอฮอล์
            </th>
            <th class="text-right border-1">
              เครื่องวัดความเร็วแบบเคลื่อนที่
            </th>
            <th class="text-right border-1">
              จุดตรวจตาม พรก.ฉุกเฉิน
            </th>
            <th class="text-right border-1">
              จุดตรวจสอบน้ำหนักรถบรรทุก
            </th>
            <th class="text-right border-1">
              ป้องกันปราบปรามอาชญากรรม
            </th>
            <th class="text-right border-1">
              ตรวจยาเสพติด
            </th>
            <th class="text-right border-1">
              ป้องกันการแพร่ระบาดโควิด
            </th>
            <th class="text-right border-1">
              ควันดำ/เสียงดัง
            </th>
            <th class="text-right border-1">
              ความมั่นคงตามแนวชายแดน
            </th>
            <th class="text-right border-1">
              อื่นๆ
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="b in stations">
            <tr :key="b.id" v-if="getTotal(b.id) > 0">
              <td>{{ b.name_abbr }}</td>
              <td class="text-right border-1">{{ getTotal(b.id) }}</td>
              <td class="text-right border-1">{{ getStat(b.id, 2) }}</td>
              <td class="text-right border-1">{{ getStat(b.id, 3) }}</td>
              <td class="text-right border-1">{{ getStat(b.id, 1) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 1) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 2) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 3) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 4) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 5) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 6) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 7) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 8) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 9) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 10) }}</td>
              <td class="text-right border-1">{{ getSubStat(b.id, 11) }}</td>
            </tr>
          </template>
          <tr>
              <td>รวม</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td class="text-right border-1">{{ getSubStatTotal(1) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(2) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(3) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(4) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(5) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(6) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(7) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(8) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(9) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(10) }}</td>
              <td class="text-right border-1">{{ getSubStatTotal(11) }}</td>
            </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  methods: {
    getStat(id, type) {
      return this.events.filter(e => e.extendedProps.station == id && e.extendedProps.type == type).length
    },
    getSubStat(id, type) {
      return this.events.filter(e => e.extendedProps.station == id && (e.extendedProps.subtype && e.extendedProps.subtype.find(subType => subType == type))).length
      // return this.events.filter(e => e.extendedProps.station == id && e.extendedProps.subtype == type).length
    },
    getTotal(id) {
      return this.events.filter(e => e.extendedProps.station == id).length
    },
    getSubStatTotal(type) {
      return this.events?.filter(e => (e.extendedProps.subtype && e.extendedProps.subtype && e.extendedProps.subtype.find(subType => subType == type)))?.length
    },
  },
  computed: {
    ...mapGetters('Appfilter', ['stations']),
  },
  props: {
    events: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>

<style>
/* .border-1 {
  border: 1px solid rgb(153, 153, 153);
} */

table, th, td {
  border: 1px solid rgb(236, 236, 236);
}
</style>

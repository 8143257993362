<template>
  <v-card class="ml-auto" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">
          {{ title }}
        </div>
        <v-list-item-title class="headline mb-1">
          {{ number }} <v-icon>mdi-handcuffs</v-icon> <span class="red--text"> {{ number2 }}</span>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-avatar tile size="80">
        <v-icon size="79">{{ icon }}</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ['title', 'number', 'icon', 'color', 'number2'],
}
</script>

<style></style>

<template>
  <!-- <fullscreen ref="fullscreen" @change="fullscreenChange"> -->
  <v-card class="mx-auto" elevation="2" :loading="loading" ref="mapcard">
    <a-btn @showAll="fitBounds" @toggleTrafficLayer="toggleTrafficLayer" @toggleFullscreen="toggleFullscreen"></a-btn>
    <v-container>
      <longdo-map @load="loadMap" :zoom="6" :lastView="false">
      </longdo-map>
    </v-container>
    <cluster-dialog ref="cDlg" v-model="dialog" @click:item="$emit('markerDblClick', $event)"></cluster-dialog>
  </v-card>

  <!-- </fullscreen> -->
</template>

<script>
import { getMarkers } from '@/api/gmapmarker'
import { gmapApi } from 'gmap-vue'
import coneMarker from '@/assets/cone-marker.png'
import alcoholMarker from '@/assets/alcohol-marker.png'
import speedCamMarker from '@/assets/speed-camera-marker.png'
import pcpMarker from '@/assets/pcp-marker.png'
import m1 from '@/assets/clusters/m1.png'
import m2 from '@/assets/clusters/m2.png'
import m3 from '@/assets/clusters/m3.png'
import m4 from '@/assets/clusters/m4.png'
import m5 from '@/assets/clusters/m5.png'
import aBtn from './partials/aBtn'
import clusterDialog from './partials/clusterDialog'
import { LongdoMap } from 'longdo-map-vue'
export default {
  props: {
    // markers: {
    //   type: Array,
    //   default() {
    //     return []
    //   },
    // },
    mapHeight: {
      type: [String, Number],
    },
  },
  components: {
    aBtn,
    clusterDialog,
    LongdoMap
  },
  computed: {
    google: gmapApi,
  },
  watch: {
    markers(v) {
      if (v.length > 0) {
        this.fitBounds()
      }
    },
  },
  mounted() {
    // this.$refs.map.$mapPromise.then(map => {
    //   this.map = map
    //   this.trafficLayer = new this.google.maps.TrafficLayer()
    //   this.trafficLayer.setMap(map)
    // })
  },
  methods: {
    loadMap(map) {

      // map.Overlays.clear();

      map.Layers.setBase(longdo.Layers.NORMAL);
      map.Layers.add(longdo.Layers.TRAFFIC);

      var _this = this

      // let _marker = new longdo.Marker({  lat: 13.7563, lon: 100.5018 })
      // console.log(_marker)
      // map.Overlays.add(_marker)

      this.map = map

      
    },
    fetchMarkers(ids) {
      this.markers = []
      if (ids.length == 0) {
        this.markers = []
        return
      }
      this.loading = true
      getMarkers(ids)
        .then(res => {
          // console.log(res[0])
          // this.markers = res

          for(let marker of res) {
            console.log(marker)
            let _marker = new longdo.Marker({  lat: marker?.position?.lat, lon: marker?.position?.lng },
            {
              title: marker?.title,
              detail: `${marker?.station?.name_abbr} วันที่ ${marker?.start_at} - ${marker?.end_at}`
            })
            // let _marker = new longdo.Marker({ lon: marker?.position?.lon , lat: marker?.position?.lat })
            // console.log(_marker)
            this.map.Overlays.add(_marker)
          }

          // this.markers?.map((_marker) => {
          //   var marker = new longdo.Marker({ lon: _marker?.position?.lat , lat: _marker?.position?.lng })
          //   // this.map.Overlays.add(marker)
          // })
          // console.log(res)
        })
        .catch(err => {
          this.markers = []
          // console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    fitBounds() {
      let b = new this.google.maps.LatLngBounds()
      this.markers.forEach((item, index) => {
        if (item == undefined) console.log(index)
        b.extend({
          lat: item.position.lat,
          lng: item.position.lng,
        })
      })

      this.$refs.map.fitBounds(b)
    },
    toggleTrafficLayer() {
      this.showTraffic = !this.showTraffic
      // this.$refs.map.$mapPromise.then(map => {
      if (this.showTraffic) this.trafficLayer.setMap(this.map)
      else this.trafficLayer.setMap(null)
      // })
    },
    clusterClick(c) {
      // console.log(c)
      const mks = c.getMarkers()
      const map = c.getMap()
      const z = map.getZoom()
      if (z == 22) {
        // console.log(mks.map(m => m.title.split('-')[0]))
        const ids = mks.map(m => m.title.split('-')[0])
        this.$refs.cDlg.list(ids).then(() => {
          this.dialog = true
        })
      }
    },
    markerDblClick(m, i) {
      this.$emit('markerDblClick', this.markers[i].id)
    },
    markerClick(m, i) {
      const mk = this.markers[i]
      // date format
      const dateTh =
        this.moment(mk.start_at)
          .add(543, 'year')
          .format('D MMMYY HH:mm') +
        ' - ' +
        this.moment(mk.end_at)
          .add(543, 'year')
          .format('D MMMYY HH:mm')
      this.infoWindowPos = m.position
      this.infoOptions.content = `<strong>${mk.title}</strong> (${mk.station.name_abbr})<br>${dateTh}`
      this.setCenter(mk.position)
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == i) {
        this.infoWinOpen = !this.infoWinOpen
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.currentMidx = i
      }
    },
    setCenter(position) {
      // this.$refs.map.$mapPromise.then(map => {
      this.map.panTo(position)
      this.map.setZoom(17)
      // })
    },
    // utils
    markerIcon(type) {
      switch (type) {
        case 1:
          return coneMarker
        case 2:
          return pcpMarker
        case 3:
          return speedCamMarker
        default:
          return coneMarker
      }
    },
    toggleFullscreen() {
      console.log(this.$refs.mapcard.$el)
      // this.$fullscreen.toggle() // recommended
    },
  },
  data() {
    return {
      gmapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 6,
        },
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      mapStyles: {
        width: '100hh',
        height: this.mapHeight,
      },
      clusterStyles: [
        {
          textColor: 'white',
          textSize: 12,
          textLineHeight: 53,
          url: m1,
          height: 53,
          width: 52,
        },
        {
          textColor: 'white',
          textSize: 13,
          textLineHeight: 56,
          url: m2,
          height: 56,
          width: 55,
        },
        {
          textColor: 'white',
          textSize: 14,
          textLineHeight: 66,
          url: m3,
          height: 66,
          width: 65,
        },
        {
          textColor: 'white',
          textSize: 15,
          textLineHeight: 78,
          url: m4,
          height: 78,
          width: 77,
        },
        {
          textColor: 'white',
          textSize: 16,
          textLineHeight: 90,
          url: m5,
          height: 90,
          width: 89,
        },
      ],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      fullscreen: false,
      loading: false,
      markers: [],
      showTraffic: true,
      trafficLayer: null,
      map: null,
      dialog: false,
    }
  },
}
</script>

<style>
.fullscreen .target-element {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

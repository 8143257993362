<template>
  <v-card class="mx-auto" :loading="loading">
    <v-container>
      <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    </v-container>
  </v-card>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { getEvent } from '@/api/calendar'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    FullCalendar,
  },

  mounted() {
    let fc = this.$refs.fullCalendar.getApi()
    this.fcApi = fc
    // console.log(fc)
  },
  created() {},
  methods: {
    getEvents(info, success, fail) {
      this.loading = true
      let start = this.dateStart === null ? info.start.toISOString() : this.dateStart
      let end = this.dateEnd === null ? info.end.toISOString() : this.dateEnd
      const data = {
        start: start,
        end: end,
        bureau: this.bureauId,
        division: this.divisionId,
        station: this.stationId,
        showToday: this.showToday,
        showActive: this.showActive,
        showPermanent: this.showPermanent,
        showNotFinish: this.showNotFinish,
        type: this.onlyType,
        subtype: this.onlySubType,
      }
      getEvent(data)
        .then(res => {
          success(res)
          this.$emit('events-set', res)
          //   console.log(res)
        })
        .catch(err => fail(err))
        .finally(() => {
          this.loading = false
        })
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(info) {
      // let title = prompt("Please enter a new title for your event");
      // let calendarApi = selectInfo.view.calendar;
      // calendarApi.unselect(); // clear date selection
      // if (title) {
      //   calendarApi.addEvent({
      //     id: "",
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   });
      // }
      let calApi = info.view.calendar
      // calApi.changeView('timeGridDay', info.startStr)
    },
    handleEventClick(info) {
      // console.log()
      this.fcApi = info.view.calendar
      this.$emit('setMapCenter', info.event.extendedProps.position)
      this.$emit('event-click', info.event.id)
    },
    handleEvents(events) {},
    async handleEventChange(info) {
      let event = info.event

      let newDateStr = this.$moment(event.startStr).format('D-M-YYYY (HH:MM)')
      // console.log(newDateStr)
    },
    refreshFc() {
      if (this.fcApi == null) return

      this.fcApi.refetchEvents()
    },
    updateSize() {
      if (this.fcApi == null) return
      console.log('updated size')
      this.fcApi.updateSize()
      this.fcApi.render()
    },
  },
  computed: {
    ...mapGetters('Appfilter', [
      'bureaus',
      'bureauId',
      'divisionId',
      'stationId',
      'divisions',
      'stations',
      'dateStart',
      'dateEnd',
      'datesSelected',
    ]),
  },
  watch: {
    dateStart(v) {
      if (v != null) {
        // console.log(v)
        this.fcApi.gotoDate(v)
      }
    },
    dateEnd(v, ov) {
      // console.log(`the dates changed ${ov} to ${v}`)
      // console.log(this.fcApi)
      if (v) {
        if (this.fcApi === null || this.fcApi === undefined) return
        this.fcApi.setOption('visibleRange', {
          start: this.dateStart,
          end: this.dateEnd,
        })

        this.fcApi.refetchEvents()
      }
    },
    bureauId(v) {
      if (v != '') {
        this.fcApi.refetchEvents()
      }
    },
    divisionId(v) {
      if (v != '') {
        this.fcApi.refetchEvents()
      }
    },
    stationId(v) {
      if (v != '') {
        this.fcApi.refetchEvents()
      }
    },
    showActive() {
      this.fcApi.refetchEvents()
    },
    showToday() {
      this.fcApi.refetchEvents()
    },
    showPermanent() {
      this.fcApi.refetchEvents()
    },
    showNotFinish() {
      this.fcApi.refetchEvents()
    },
    onlyType() {
      this.fcApi.refetchEvents()
    },
    onlySubType() {
      this.fcApi.refetchEvents()
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: '',
        },
        timeZone: 'Asia/Bangkok',
        initialView: 'dayGridMonth',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        showNonCurrentDates: false,
        // handleWindowResize: false,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventChange: this.handleEventChange,
        events: this.getEvents,
        themeSystem: 'bootstrap',
      },
      loading: false,
      fcApi: null,
      ...mapState('Appfilter', {
        start: 'dateStart',
        end: 'dateEnd',
      }),
    }
  },

  props: {
    showToday: {
      type: Boolean,
      default: true,
    },
    showActive: {
      type: Boolean,
      default: true,
    },
    showNotFinish: {
      type: Boolean,
      default: true,
    },
    showPermanent: {
      type: Boolean,
      default: false,
    },
    onlyType: {},
    onlySubType: {},
  },
}
</script>

<style></style>

<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card>
        <v-card-title>
          <v-icon color="orange">
            mdi-traffic-cone
          </v-icon>
          <span class="subtitle-1">จุดตรวจทั้งหมด ({{ checkpoints.length }})</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-list two-line>
          <template v-for="(item, i) in checkpoints">
            <v-divider v-if="i != 0" :key="`div-${i}`"></v-divider>
            <v-list-item :key="i" @click="$emit('click:item', item.id)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.station }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { clusters } from '@/api/checkpoint'
export default {
  methods: {
    list(ids) {
      return new Promise((resolve, reject) => {
        clusters(ids)
          .then(res => {
            this.checkpoints = res
            resolve(res)
          })
          .catch(() => reject())
      })
    },
  },
  data() {
    return {
      checkpoints: [],
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
